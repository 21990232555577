import { renderBrickIcon } from '@amedia/brick-icon/template';

import { breakingNewsTemplate } from '../../breaking/breaking-news';
import { TeaserType, Title } from '../../../types';
import {
  headingStyle,
  pretitleStyle,
  titleWrapperStyle,
  ratingStyle,
} from '../title-style';

function mapToTeaserType(teaserType: TeaserType): TeaserType {
  switch (teaserType) {
    case 'opinion':
      return teaserType;
    case 'commercial':
      return teaserType;
    case 'feature':
      return teaserType;
    case 'sport':
      return teaserType;
    default:
      return 'story';
  }
}

export default function getTemplateStructure(
  data: Title,
  useAutolayout: boolean
) {
  const {
    title = '',
    breakingNews,
    isRelatedTeaser,
    overline,
    theme,
    marker,
    rating,
    skin,
    teaserType,
  } = data;
  const teaserTypeToUse = mapToTeaserType(teaserType);

  const breakingNewsHTML = breakingNews
    ? breakingNewsTemplate(breakingNews)
    : '';

  const iconRating = rating
    ? `<span class="rating-icon ${ratingStyle({
        theme,
      })}">${renderBrickIcon({
        dataIconId: `dice-${rating}`,
        dataIconText: `Terningkast ${rating}`,
      })}</span>`
    : '';

  const pretitle = (strings, marker, overlineColor, value) => {
    if (!value) {
      return '';
    }

    const styles = pretitleStyle({
      marker,
      skin,
      teaserType: teaserTypeToUse,
      theme,
    });

    if (marker && value) {
      return `<h3 class="pretitle ${styles}" ${overlineColor}>
    <span itemprop="alternativeHeadline"><span itemprop="marker">${value}</span></span>
    </h3>`;
    }

    let str = '';
    if (value) {
      str += `<h3 class="pretitle ${styles} ${overlineColor} itemprop="alternativeHeadline">${
        value || ''
      }</h3>`;
    }
    return str;
  };

  function titleWrapper(strings, marker, ...values) {
    let str = '';
    values.forEach((value) => {
      str += value;
    });
    const wrapperStyles = titleWrapperStyle({
      teaserType: teaserTypeToUse,
      skin,
      theme,
      marker,
      useAutolayout,
    });

    if (marker) {
      return `<span class="titleWrapper ${wrapperStyles}">
    <span itemprop="headline"><span itemprop="marker">${str}</span></span>
    </span>`;
    }
    return `<span class="titleWrapper ${wrapperStyles}">
    <span itemprop="headline">${str}</span>
    </span>`;
  }

  const titleText = `<span itemprop="titleText">${title}</span>`;

  const breakingNewsText = breakingNews?.breakingNewsText
    ? breakingNews.breakingNewsText
    : '';

  const titleChars = breakingNewsText.length + title.length;
  const titleWords = breakingNewsText.length
    ? title.split(' ').length + breakingNewsText?.split(' ').length
    : title.split(' ').length;

  function renderTitle(strings, isRelatedTeaser, titleText) {
    const stylies = headingStyle({
      teaserType: teaserTypeToUse,
      isBreaking: !!breakingNews?.breakingNews,
      skin,
      marker,
      useAutolayout,
    });
    if (isRelatedTeaser) {
      return `<h3 class="title ${stylies}">${titleText}</h3>`;
    }
    return `<h2 class="title ${stylies}" data-title-words="${titleWords}" style="--brick-teaser-title-length:${titleChars};">${titleText}</h2>`;
  }

  const structureTemplate = {
    charlie: [
      pretitle`${marker} ${false} ${overline}`,
      renderTitle`${isRelatedTeaser} ${titleWrapper`${marker} ${breakingNewsHTML} ${iconRating} ${titleText}`}`,
    ],
    default: [
      pretitle`${marker} ${false} ${overline}`,
      renderTitle`${isRelatedTeaser} ${titleWrapper`${marker} ${breakingNewsHTML} ${iconRating} ${titleText}`}`,
    ],
    nettavisen: [
      pretitle`${marker} ${false} ${overline}`,
      renderTitle`${isRelatedTeaser} ${titleWrapper`${marker} ${breakingNewsHTML} ${iconRating} ${titleText}`}`,
    ],
  };

  return (structureTemplate[theme] || structureTemplate.default).join('');
}
