import { css } from '@amedia/brick-tokens';

//we can add typing
export const imageStyle = css({
  '@property --brick-image-sizes': `{
    syntax: "*";
    inherits: true;
    initial-value: "" ;
  }`,
  '@property --brick-image-height': `{
    syntax: "<length> | <percentage> | auto";
    inherits: false;
    initial-value: "auto" ;
  }`,
  '@property --brick-image-width': `{
    syntax: "<length> | <percentage> | auto";
    inherits: false;
    initial-value: "";
  }`,
  '@property --brick-image-objectfit': `{
    syntax: "*";
    inherits: true;
    initial-value: "cover" ;
  }`,
  '@property --brick-image-color-bg': `{
    syntax: "*";
    inherits: true;
    initial-value: "cover" ;
  }`,
  '--_b-image-height': 'var(--brick-image-height, auto)',
  '--_b-image-width': 'var(--brick-image-width, 100%)',
  '--_b-image-objectfit': 'var(--brick-image-objectfit, cover)',
  '--_b-image-color-bg': 'var(--brick-image-color-bg, transparent)',
  '--_b-image-objectposition': 'var(--brick-image-objectposition, center)',
  width: 'var(--_b-image-width)',
  maxWidth: '100%',
  verticalAlign: 'middle',
  height: 'var(--_b-image-height)',
  fontStyle: 'italic',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'var(--_b-image-objectfit)',
  backgroundColor: 'var(--_b-image-color-bg)',
  objectFit: 'var(--_b-image-objectfit)',
  objectPosition: 'var(--_b-image-objectposition)',
  '@supports (aspect-ratio: 1 / 1)': {
    aspectRatio: 'auto var(--brick-image-ratio)',
  },
});
