import { css } from '@amedia/brick-tokens';

export const containerStyle = css({
  container: 'teaser-content / inline-size',
});

export const noImageStyle = css({
  '--_b-noImage-padding-content':
    'var(--brick-space-teaserContentYInsetM) var(--brick-space-teaserContentXInsetM)',

  display: 'grid',
  gridTemplateRows: 'minmax(min-content, 1fr)',
  gridTemplateColumns: 'minmax(min-content, 1fr)',
  columnGap: '0',

  '&.top-pills': {
    gridTemplateRows: 'auto minmax(min-content, 1fr)',
  },

  textDecoration: 'none',
  color: 'inherit',
  height: '100%',
  boxSizing: 'border-box',
  padding:
    'var(--b-teaser-padding-noImageContent, var(--_b-noImage-padding-content))',
  gridTemplateAreas: '"title premium"',

  '& .title_container': {
    gridArea: 'title',
  },
  '& .premium-icon': {
    gridArea: 'premium',
    placeSelf: 'end',
  },

  '& > *': {
    minWidth: '0px', // setting this to avoid minwidth auto
  },

  // Hover effect, only on screens larger than mobile (480px)
  '&:hover': {
    '& span[itemprop="titleText"]': {
      textDecorationColor: 'currentColor',
    },
  },

  '@bp532': {
    '--_b-noImage-padding-content':
      'var(--brick-space-teaserContentYInsetL) var(--brick-space-teaserContentXInsetL)',
  },

  variants: {
    teaserType: {
      teaser: {},
      story: {},
      review: {},
      opinion: {},
      commercial: {},
      video: {},
      gallery: {},
      alt: {},
      sport: {},
      feature: {},
      untold: {},
    },
    theme: {
      alfa: {},
      bravo: {
        '& .premium-icon': {
          marginTop: 'calc($teaserStackCM - $teaserStackBM)',
        },
        '@bp532': {
          '& .premium-icon': {
            marginTop: 'calc($teaserStackCL - $teaserStackBL)',
          },
        },
      },
      charlie: {},
      nettavisen: {
        height: 'fit-content',
      },
      alt: {},
    },
    topPills: {
      true: {},
      false: {},
    },
    bottomPills: {
      true: {},
      false: {},
    },
    premium: {
      true: {},
      false: {},
    },
    marker: {
      true: {},
      false: {
        rowGap: '$teaserStackBM',
        '@bp532': {
          rowGap: '$teaserStackBL',
        },
      },
    },
  },
  defaultVariants: {
    teaserType: 'story',
    theme: 'alfa',
    topPills: false,
    bottomPills: false,
    premium: false,
  },

  compoundVariants: [
    {
      topPills: false,
      bottomPills: false,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      topPills: false,
      bottomPills: false,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"title" "premium"',
      },
    },
    {
      topPills: false,
      bottomPills: false,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      topPills: false,
      bottomPills: false,
      premium: true,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      topPills: false,
      bottomPills: false,
      premium: false,
      css: {
        gridTemplateAreas: '"title"',
      },
    },
    {
      topPills: true,
      bottomPills: false,
      premium: false,
      css: {
        gridTemplateAreas: '"top-pills" "title"',
      },
    },
    {
      topPills: false,
      bottomPills: true,
      premium: false,
      css: {
        gridTemplateAreas: '"title" "bottom-pills"',
      },
    },
    {
      topPills: true,
      bottomPills: true,
      premium: false,
      css: {
        gridTemplateAreas: '"top-pills" "title" "bottom-pills"',
      },
    },
    {
      topPills: true,
      bottomPills: false,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"top-pills top-pills" "title premium"',
      },
    },
    {
      topPills: true,
      bottomPills: false,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"top-pills" "title" "premium"',
      },
    },
    {
      topPills: true,
      bottomPills: false,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"top-pills top-pills" "title premium"',
      },
    },
    {
      topPills: false,
      bottomPills: true,
      premium: true,
      css: {
        gridTemplateAreas: '"title title" "bottom-pills premium"',
      },
    },
    {
      topPills: true,
      bottomPills: true,
      premium: true,
      css: {
        gridTemplateAreas:
          '"top-pills top-pills" "title title" "bottom-pills premium"',
      },
    },
  ],
});

export const noImageTopPillContainer = css({
  gridArea: 'top-pills',
  maxWidth: '100%',
  alignSelf: 'start',
});

export const noImageBottomPillContainer = css({
  gridArea: 'bottom-pills',
  paddingTop: 'calc($teaserStackCM - $teaserStackBM)',
  '@bp532': {
    paddingTop: 'calc($teaserStackCL - $teaserStackBL)',
  },

  variants: {
    marker: {
      true: {
        paddingTop: 0,
        '@bp532': {
          paddingTop: 0,
        },
      },
    },
  },
});
