import { css } from '../../styles';

export const titleContainerStyle = css({
  gridColumn: '1',
  display: 'flex',
  flexDirection: 'column',
  gap: '$teaserStackTitleM',
  flex: '1',
  //@media over 532
  '@bp532': {
    gap: '$teaserStackTitleL',
  },
  // @media (min-width: 533px) and (max-width: 1000px)
  '@bp1': {
    gap: '$teaserStackTitleL',
  },

  variants: {
    theme: {
      nettavisen: {},
      bravo: {},
      alfa: {},
      charlie: {},
      alt: {},
    },
    marker: {
      true: {
        gap: 0,
        rowGap: 0,
        '@bp532': {
          gap: 0,
          rowGap: 0,
        },
        '@bp1': {
          gap: 0,
          rowGap: 0,
        },
        '& span[itemprop="marker"]': {
          display: 'inline',
          padding: '0px 6px 2px',
          '-webkit-box-decoration-break': 'clone',
          boxDecorationBreak: 'clone',
        },
      },
    },
    teaserType: {
      teaser: {},
      story: {},
      review: {},
      opinion: {
        flex: '1',
      },
      commercial: {},
      video: {},
      gallery: {},
      alt: {},
      sport: {},
      feature: {},
      untold: {},
    },
    isBreaking: {
      true: {},
      false: {},
    },
  },
  defaultVariants: {
    teaserType: '',
    theme: '',
    marker: false,
    isBreaking: false,
  },
  compoundVariants: [
    {
      teaserType: 'opinion',
      theme: 'nettavisen',
      marker: true,
      css: {
        '& span[itemprop="marker"]': {
          paddingTop: '0.08em',
          paddingBottom: '0.1em',
          lineHeight: '130%',
        },
      },
    },
    {
      theme: 'nettavisen',
      marker: true,
      isBreaking: true,
      css: {
        '& span[itemprop="marker"] span[itemprop="breaking-news"]': {
          display: 'inline-block',
          padding: '0px 6px 0px 0px',
        },
      },
    },
    {
      theme: 'bravo',
      teaserType: 'opinion',
      css: {
        gridColumn: '1 / 3',
        padding: '$teaserContentYInsetM $teaserContentXInsetM',

        '@bp532': {
          padding: '$teaserContentYInsetL $teaserContentXInsetL',
        },
      },
    },
    {
      theme: 'nettavisen',
      teaserType: 'opinion',
      css: {
        gridColumn: '1 / 3',
      },
    },
  ],
});

export const titleWrapperStyle = css({
  display: 'block',
  variants: {
    teaserType: {
      teaser: {},
      story: {},
      review: {},
      opinion: {},
      commercial: {},
      video: {},
      gallery: {},
      alt: {},
      sport: {},
      feature: {},
      untold: {},
    },
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    marker: {
      true: {},
      false: {},
    },
    skin: {
      sport: {},
      none: {},
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
      opinion: {},
    },
    useAutolayout: {
      true: {
        '& > span': {
          display: 'block',
        },

        fontSize: '71px', // hva er denne til tro - scorponok setter font-size i em, som er en utregning av font-size til parent - altså denne fontsizen
        '@bp1': {
          fontSize: 'calc(7.5vw - 8%)',
        },
        '@bp2': {
          /* 532px * 0.075 - (parentFont * x) = 500 * 0,75. Find the font-size without tha page padding */
          fontSize: 'calc(7.5vw - 0.902255639097744%)',
        },

        '& span[itemprop="headline"]': {
          fontSize: '71px', // hva er denne til tro - utgangspunktet i scorponoks utregning av font-size
          '@bp1': {
            fontSize: 'calc(7.5vw - 8%)',
          },
          '@bp2': {
            /* 532px * 0.075 - (parentFont * x) = 500 * 0,75. Find the font-size without tha page padding */
            fontSize: 'calc(7.5vw - 0.902255639097744%)',
          },
        },
      },
      false: {},
    },
    isBreaking: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      teaserType: 'feature',
      css: {
        fontFamily: '$titleFeatureM',
      },
    },

    {
      teaserType: 'opinion',
      theme: 'bravo',
      css: {
        textAlign: 'center',
        fontFamily: '$titleOpinionM',
      },
    },
    {
      teaserType: 'opinion',
      theme: 'charlie',
      css: {
        fontStyle: 'italic',
        fontFamily: '$titleOpinionM',
      },
    },
    {
      teaserType: 'opinion',
      theme: 'nettavisen',
      css: {
        textAlign: 'left',
      },
    },
    {
      marker: true,
      skin: 'custom-one',
      css: {
        '& span[itemprop="marker"]': {
          color:
            'var(--custom-background-color-one-front, $supportiveCustomOneFg)',
          backgroundColor:
            'var(--custom-background-color-one, $supportiveCustomOneBg)',
        },
      },
    },
    {
      marker: true,
      skin: 'custom-two',
      css: {
        '& span[itemprop="marker"]': {
          color:
            'var(--custom-background-color-two-front, $supportiveCustomTwoFg)',
          backgroundColor:
            'var(--custom-background-color-two, $supportiveCustomTwoBg)',
        },
      },
    },
  ],
});

const titleFontStyle = {
  fontFamily: '$titleXl',
  lineHeight: '$titleXl',
  letterSpacing: '$titleXl',
  fontWeight: '$titleXl',
};

const commercialFontStyles = {
  fontstyle: 'titleCommercialXs',
  fontSize: 'clamp($titleCommercialXs, 1.8vw + 2.5rem, $titleCommercialXxl)',
  '--_brick-teaser-title-min-size':
    'var(--b-teaser-fontSize-min-title, var(--brick-fontSizes-titleCommercialXs))',
  '--_brick-teaser-title-max-size': 'var(--b-teaser-fontSize-max-title, 20cqi)',
  '--_brick-teaser-calc-max-letters':
    'max(var(--brick-teaser-title-length, 0),5)',
  '--brick-teaser-letter-count-calc':
    'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountCommercialSCqi))',
  '--brick-teaser-cqi-clamp':
    'clamp(var(--brick-fontSizes-titleCommercialXs), var(--brick-sizes-titleCommercialCqi) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleCommercialXxl)))',
  '@supports (container-type: inline-size)': {
    fontSize:
      'max(var(--brick-teaser-cqi-clamp), var(--brick-teaser-letter-count-clamp))',
    '@container teaser-content (width > 532px)': {
      '--brick-teaser-letter-count-calc':
        'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountCommercialMCqi))',
    },
  },
};

const breakingFontStyle = {
  fontstyle: 'titleBreakingM',
  fontSize: 'clamp($titleBreakingS, 1.8vw + 2.5rem, $titleBreakingL)',
  '--_brick-teaser-title-min-size':
    'var(--b-teaser-fontSize-min-title, var(--brick-fontSizes-titleBreakingXs))',
  '--_brick-teaser-title-max-size': 'var(--b-teaser-fontSize-max-title, 12cqi)',

  '--_brick-teaser-calc-max-letters':
    'max(var(--brick-teaser-title-length, 0),5)',
  '--brick-teaser-letter-count-calc':
    'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountBreakingSCqi))',
  '&[data-title-words="1"]:has(breaking-text)': {
    '--brick-teaser-letter-count-calc':
      'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountSingleWordSCqi))',
  },
  '--brick-teaser-cqi-clamp':
    'clamp(var(--_brick-teaser-title-min-size), (var(--brick-sizes-titleBreakingCqi)*0.4) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleBreakingS)))',
  '@supports (container-type: inline-size)': {
    fontSize:
      'max(var(--brick-teaser-cqi-clamp),var(--brick-teaser-letter-count-clamp))',
    '@container teaser-content (width > 250px)': {
      '--_brick-teaser-title-max-size':
        'var(--b-teaser-fontSize-max-title, 14cqi)',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleBreakingXs), var(--brick-sizes-titleBreakingCqi) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleBreakingXxl)))',
    },
    '@container teaser-content (width > 532px)': {
      '--_brick-teaser-title-min-size':
        'var(--b-teaser-fontSize-min-title, var(--brick-fontSizes-titleBreakingM))',
      '--_brick-teaser-title-max-size':
        'var(--b-teaser-fontSize-max-title, 18cqi)',
      '&[data-title-words="1"]:has(breaking-text)': {
        '--brick-teaser-letter-count-calc':
          'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountSingleWordMCqi))',
      },

      '--brick-teaser-letter-count-calc':
        'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountBreakingMCqi))',
      '&:has(breaking-text)': {
        '--brick-teaser-letter-count-calc':
          'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountBreakingMCqi))',
      },

      fontstyle: 'titleBreakingXl',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleBreakingM), var(--brick-sizes-titleBreakingCqi) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleBreakingXxl)))',
      fontSize:
        'max(var(--brick-teaser-cqi-clamp),var(--brick-teaser-letter-count-clamp))',
    },
    '@container teaser-content (width > 680px)': {
      '--_brick-teaser-title-max-size':
        'var(--b-teaser-fontSize-max-title, 22cqi)',
      fontstyle: 'titleBreakingXl',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleBreakingM), var(--brick-sizes-titleBreakingCqi) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleBreakingXxl)))',
      fontSize:
        'max(var(--brick-teaser-cqi-clamp),var(--brick-teaser-letter-count-clamp))',
    },
  },
};

const featureFontStyle = {
  fontstyle: 'titleFeatureM',
  fontSize: 'clamp($titleFeatureXs, 1.5vw + 0.5rem, $titleFeatureL)',
  '--_brick-teaser-title-min-size':
    'var(--b-teaser-fontSize-min-title, var(--brick-fontSizes-titleFeatureXs))',
  '--_brick-teaser-title-max-size': 'var(--b-teaser-fontSize-max-title, 10cqi)',
  '--_brick-teaser-calc-max-letters':
    'max(var(--brick-teaser-title-length, 0),5)',
  '--brick-teaser-letter-count-calc':
    'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountFeatureSCqi))',
  '&[data-title-words="1"]': {
    '--brick-teaser-letter-count-calc':
      'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountSingleWordSCqi))',
  },

  '--brick-teaser-cqi-clamp':
    'clamp(var(--brick-fontSizes-titleFeatureXs), (var(--brick-sizes-titleFeatureCqi)*0.4) + var(--brick-fontSizes-titleFeatureCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleFeatureS)))',
  '@supports (container-type: inline-size)': {
    fontSize:
      'max(var(--brick-teaser-cqi-clamp), var(--brick-teaser-letter-count-clamp))',
    '@container teaser-content (width > 250px)': {
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleFeatureXs), var(--brick-sizes-titleFeatureCqi) + var(--brick-fontSizes-titleFeatureCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleFeatureM)))',
    },
    '@container teaser-content (width > 350px)': {
      '--brick-teaser-letter-count-calc':
        'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountFeatureMCqi))',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleFeatureS), var(--brick-sizes-titleFeatureCqi) + var(--brick-fontSizes-titleFeatureCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleFeatureXl)))',
    },
    '@container teaser-content (width > 580px)': {
      '--_brick-teaser-title-max-size':
        'var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleFeatureXxl))',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleFeatureM), var(--brick-sizes-titleFeatureCqi) + var(--brick-fontSizes-titleFeatureCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleFeatureXxl)))',
    },
  },
};

const defaultTitleFontSizes = {
  '--_brick-teaser-title-min-size':
    'var(--b-teaser-fontSize-min-title, var(--brick-fontSizes-titleXs))',
  '--_brick-teaser-title-max-size': 'var(--b-teaser-fontSize-max-title, 10cqi)',
  '--_brick-teaser-calc-max-letters':
    'max(var(--brick-teaser-title-length, 0),5)',
  '--brick-teaser-letter-count-calc':
    'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountSCqi))',

  '&[data-title-words="1"]': {
    '--_brick-teaser-title-max-size':
      'var(--b-teaser-fontSize-max-title, 18cqi)',
    '--brick-teaser-letter-count-calc':
      'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountSingleWordSCqi))',
  },
  '--brick-teaser-cqi-clamp':
    'clamp(var(--brick-fontSizes-titleXs), (var(--brick-sizes-titleCqi)*0.4) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleS)))',
  'font-size': '$titleM', //safari12 fallback
  fontSize: 'clamp($titleXs, 1.8vw + 1rem, $titleXl)',
  '@supports (container-type: inline-size)': {
    fontSize:
      'max(var(--brick-teaser-cqi-clamp), var(--brick-teaser-letter-count-clamp))',
    '@container teaser-content (width > 250px)': {
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleXs), (var(--brick-sizes-titleCqi)*0.8) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleM)))',
    },

    '@container teaser-content (width > 350px)': {
      '--_brick-teaser-title-max-size':
        'var(--b-teaser-fontSize-max-title, 14cqi)',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleS), var(--brick-sizes-titleCqi) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleXl)))',
    },
    '@container teaser-content (width > 532px)': {
      '--_brick-teaser-title-min-size':
        'var(--b-teaser-fontSize-min-title, var(--brick-fontSizes-titleM))',
      '--_brick-teaser-title-max-size':
        'var(--b-teaser-fontSize-max-title, 20cqi)',
      '&[data-title-words="1"]': {
        '--brick-teaser-letter-count-calc':
          'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountSingleWordMCqi))',
      },
      '--brick-teaser-letter-count-calc':
        'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountMCqi))',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleM), var(--brick-sizes-titleCqi) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleXxl)))',
    },
    '@container teaser-content (width > 680px)': {
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleM), var(--brick-sizes-titleCqi) + var(--brick-fontSizes-titleCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleXxl)))',
    },
  },
};

const opinionTitleFontSizes = {
  fontstyle: 'titleOpinionM',
  fontSize:
    'var(--b-teaser-fontSize-opinion-title, clamp(var(--brick-fontSizes-titleOpinionXs), 1.5vw + 0.5rem,var(--brick-fontSizes-titleOpinionL)))',
  '--_brick-teaser-title-min-size':
    'var(--b-teaser-fontSize-min-title, var(--brick-fontSizes-titleOpinionXs))',
  '--_brick-teaser-title-max-size': 'var(--b-teaser-fontSize-max-title, 10cqi)',
  '--_brick-teaser-calc-max-letters':
    'max(var(--brick-teaser-title-length, 0),5)',
  '--brick-teaser-letter-count-calc':
    'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountOpinionSCqi))',
  '&[data-title-words="1"]': {
    '--_brick-teaser-title-max-size':
      'var(--b-teaser-fontSize-max-title, 18cqi)',
    '--brick-teaser-letter-count-calc':
      'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountSingleWordSCqi))',
  },
  //Her settes --brick-teaser-cqi-clamp
  '--brick-teaser-cqi-clamp':
    'clamp(var(--brick-fontSizes-titleOpinionXs), 0.5cqi + 0.5rem, var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleOpinionS)))',
  '@supports (container-type: inline-size)': {
    fontSize:
      'max(var(--brick-teaser-cqi-clamp), var(--brick-teaser-letter-count-clamp))',
    '@container teaser-content (width > 150px)': {
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleOpinionXs), (var(--brick-sizes-titleOpinionCqi)*0.5) + var(--brick-fontSizes-titleOpinionCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleOpinionS)))',
    },
    '@container teaser-content (width > 250px)': {
      '--_brick-teaser-title-max-size':
        'var(--b-teaser-fontSize-max-title, 12cqi)',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleOpinionXs), var(--brick-sizes-titleOpinionCqi) + var(--brick-fontSizes-titleOpinionCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleOpinionL)))',
    },
    '@container teaser-content (width > 532px)': {
      '--_brick-teaser-title-min-size':
        'var(--b-teaser-fontSize-min-title, var(--brick-fontSizes-titleOpinionM))',
      '--_brick-teaser-title-max-size':
        'var(--b-teaser-fontSize-max-title, 18cqi)',
      '&[data-title-words="1"]': {
        '--brick-teaser-letter-count-calc':
          'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountSingleWordMCqi))',
      },
      '--brick-teaser-letter-count-calc':
        'calc((100 / var(--_brick-teaser-calc-max-letters)) * var(--brick-sizes-letterCountOpinionMCqi))',
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleOpinionS), (var(--brick-sizes-titleOpinionCqi)*1.8) + var(--brick-fontSizes-titleOpinionCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleOpinionXxl)))',
    },
    '@container teaser-content (width > 780px)': {
      '--brick-teaser-cqi-clamp':
        'clamp(var(--brick-fontSizes-titleOpinionM), (var(--brick-sizes-titleOpinionCqi)*2) + var(--brick-fontSizes-titleOpinionCqiBase), var(--b-teaser-fontSize-max-title, var(--brick-fontSizes-titleOpinionXxl)))',
    },
  },
};

const useAutolayoutFontSizes = {
  fontSize: '5vw',
  margin: 0,
  '@bp2': {
    fontSize: '5vw',
  },
};

export const headingStyle = css({
  '--brick-teaser-letter-count-clamp':
    'clamp(var(--_brick-teaser-title-min-size), var(--brick-teaser-letter-count-calc),var(--_brick-teaser-title-max-size))',
  margin: 0,
  textWrap: 'balance',

  variants: {
    teaserType: {
      opinion: {},
      teaser: {},
      story: {},
      review: {},
      commercial: {},
      video: {},
      gallery: {},
      alt: {},
      sport: {},
      feature: {},
      untold: {},
    },
    isBreaking: {
      true: {},
      false: {},
    },
    marker: {
      true: {
        marginInlineEnd: '5px',

        '& span[itemprop="marker"]': {
          lineHeight: '1.15',
        },
      },
      false: {},
    },
    skin: {
      sport: {},
      none: {},
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
      opinion: {},
    },
    useAutolayout: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      teaserType: 'feature',
      useAutolayout: false,
      css: {
        ...featureFontStyle,
      },
    },
    {
      teaserType: 'opinion',
      useAutolayout: false,
      css: {
        ...opinionTitleFontSizes,
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'highlight',
      useAutolayout: false,
      css: {
        ...breakingFontStyle,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'highlight',
      useAutolayout: false,
      css: {
        ...breakingFontStyle,
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'none',
      useAutolayout: false,
      css: {
        ...breakingFontStyle,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'none',
      useAutolayout: false,
      css: {
        ...titleFontStyle,
        ...defaultTitleFontSizes,
        fontWeight: '$titleXl',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'black',
      useAutolayout: false,
      css: {
        ...breakingFontStyle,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'black',
      useAutolayout: false,
      css: {
        ...titleFontStyle,
        ...defaultTitleFontSizes,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'custom-one',
      useAutolayout: false,
      css: {
        ...breakingFontStyle,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'custom-one',
      useAutolayout: false,
      css: {
        ...titleFontStyle,
        ...defaultTitleFontSizes,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'custom-two',
      useAutolayout: false,
      css: {
        ...breakingFontStyle,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'custom-two',
      useAutolayout: false,
      css: {
        ...titleFontStyle,
        ...defaultTitleFontSizes,
        fontWeight: '$titleXl',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'custom-three',
      useAutolayout: false,
      css: {
        ...breakingFontStyle,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'custom-three',
      useAutolayout: false,
      css: {
        ...titleFontStyle,
        ...defaultTitleFontSizes,
        fontWeight: '$titleXl',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'sport',
      useAutolayout: false,
      css: {
        ...breakingFontStyle,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'sport',
      useAutolayout: false,
      css: {
        ...titleFontStyle,
        ...defaultTitleFontSizes,
        fontWeight: '$titleXl',
      },
    },
    {
      teaserType: 'commercial',
      useAutolayout: false,
      css: {
        ...commercialFontStyles,
      },
    },
    {
      teaserType: 'sport',
      useAutolayout: false,
      css: {
        ...titleFontStyle,
        ...defaultTitleFontSizes,
      },
    },
    //useAutolayout
    {
      teaserType: 'feature',
      useAutolayout: true,
      css: {
        fontstyle: 'titleFeatureM',
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'opinion',
      useAutolayout: true,
      css: {
        fontstyle: 'titleOpinionM',
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'highlight',
      useAutolayout: true,
      css: {
        fontstyle: 'titleBreakingM',
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'highlight',
      useAutolayout: true,
      css: {
        fontstyle: 'titleBreakingM',
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'none',
      useAutolayout: true,
      css: {
        fontstyle: 'titleBreakingM',
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'none',
      useAutolayout: true,
      css: {
        ...titleFontStyle,
        ...useAutolayoutFontSizes,
        fontWeight: '$titleXl',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'black',
      useAutolayout: true,
      css: {
        ...breakingFontStyle,
        ...useAutolayoutFontSizes,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'black',
      useAutolayout: true,
      css: {
        ...titleFontStyle,
        ...useAutolayoutFontSizes,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'custom-one',
      useAutolayout: true,
      css: {
        ...breakingFontStyle,
        ...useAutolayoutFontSizes,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'custom-one',
      useAutolayout: true,
      css: {
        ...titleFontStyle,
        ...useAutolayoutFontSizes,
        fontWeight: '$titleBreakingDarkM',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'custom-two',
      useAutolayout: true,
      css: {
        ...breakingFontStyle,
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'custom-two',
      useAutolayout: true,
      css: {
        ...titleFontStyle,
        ...useAutolayoutFontSizes,
        fontWeight: '$titleXl',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'custom-three',
      useAutolayout: true,
      css: {
        ...breakingFontStyle,
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'custom-three',
      useAutolayout: true,
      css: {
        ...titleFontStyle,
        ...useAutolayoutFontSizes,
        fontWeight: '$titleXl',
      },
    },
    {
      teaserType: 'story',
      isBreaking: true,
      skin: 'sport',
      useAutolayout: true,
      css: {
        ...breakingFontStyle,
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'story',
      isBreaking: false,
      skin: 'sport',
      useAutolayout: true,
      css: {
        ...titleFontStyle,
        ...useAutolayoutFontSizes,
        fontWeight: '$titleXl',
      },
    },
    {
      teaserType: 'commercial',
      useAutolayout: true,
      css: {
        ...commercialFontStyles,
        ...useAutolayoutFontSizes,
      },
    },
    {
      teaserType: 'sport',
      useAutolayout: true,
      css: {
        ...titleFontStyle,
        ...useAutolayoutFontSizes,
      },
    },
  ],
});

export const pretitleStyle = css({
  color: 'inherit',
  fontstyle: 'pretitleM',
  margin: 0,

  //@media over 532
  '@bp532': {
    fontstyle: 'pretitleXl',
  },
  // @media (min-width: 533px) and (max-width: 1000px)
  '@bp1': {
    fontstyle: 'pretitleL',
  },

  variants: {
    skin: {
      none: {},
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
      sport: {},
      opinion: {},
    },
    teaserType: {
      opinion: { fontstyle: 'pretitleM' }, // this should be redundant
      teaser: {},
      story: {},
      review: {},
      commercial: {},
      video: {},
      gallery: {},
      sport: {},
      alt: {},
      untold: {},
      feature: {
        fontstyle: 'pretitleXl',
      },
    },
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    marker: {
      true: {
        marginTop: '-1px',
        '& span[itemprop="marker"]': {
          lineHeight: '1.30',
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      teaserType: 'opinion',
      theme: 'bravo',
      css: {
        textAlign: 'center',
      },
    },
    {
      teaserType: 'opinion',
      theme: 'nettavisen',
      css: {
        textAlign: 'left',
      },
    },
    {
      marker: true,
      skin: 'custom-one',
      css: {
        '& span[itemprop="marker"]': {
          color:
            'var(--custom-background-color-one-front, $supportiveCustomOneFg)',
          backgroundColor:
            'var(--custom-background-color-one, $supportiveCustomOneBg)',
        },
      },
    },
    {
      marker: true,
      skin: 'custom-two',
      css: {
        '& span[itemprop="marker"]': {
          color:
            'var(--custom-background-color-two-front, $supportiveCustomTwoFg)',
          backgroundColor:
            'var(--custom-background-color-two, $supportiveCustomTwoBg)',
        },
      },
    },
  ],
});

export const ratingStyle = css({
  display: 'inline-block',
  position: 'relative',
  marginInlineEnd: '0.9em',

  '& brick-icon-v2': {
    display: 'inline-flex',
    marginInlineStart: 'calc($x1 * 0.2)',
    marginInlineEnd: 'calc($x1 * 0.3)',
    color: '$iconsDiceBg',
  },
  '& div': {
    aspectRatio: '1/1',
    position: 'absolute',
    width: 'auto',
    height: '80%',
    top: '10%',
  },
  '& svg': {
    width: 'auto',
    height: '100%',
  },

  variants: {
    theme: {
      alt: {},
      alfa: {},
      bravo: {
        marginInlineEnd: '1em',
        '& div': {
          aspectRatio: '1/1',
          position: 'absolute',
          width: 'auto',
          height: '85%',
          top: '10%',
        },
      },
      nettavisen: {
        marginInlineEnd: '1.2em',
        '& div': {
          aspectRatio: '1/1',
          position: 'absolute',
          width: 'auto',
          height: '85%',
          top: '8%',
        },
      },
      charlie: {
        marginInlineEnd: '1.1em',
        '& div': {
          aspectRatio: '1/1',
          position: 'absolute',
          width: 'auto',
          height: '85%',
          top: '5%',
        },
        '& brick-icon-v2': {
          display: 'inline-flex',
          marginInlineStart: 'calc($x1 * 0.2)',
          marginInlineEnd: 'calc($x1 * 0.3)',
          color: 'inherit',
        },
      },
    },
  },
});
